import Link from 'next/link';
import { toggleFavorite } from 'app.feature/academy/module/toggleFavorite';
import FavoriteIconSvg from 'src/app.feature/academy/component/icon/heart-icon-regular.svg';
import FavoriteIconSolidSvg from 'src/app.feature/academy/component/icon/heart-icon-solid.svg';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';
import { projectConfigState } from 'recoil/Academy/projectConfigState';
import React, { useEffect, useState } from 'react';
import { useCurrentUser } from 'src/app.hooks/useCurrentUser';
import styled from 'styled-components';

const CbtListTableRow = ({ exam, index, favorites, setFavorites }: any) => {
  const router = useRouter();
  const { user } = useCurrentUser();
  const { handleLoginRedirect } = useRecoilValue(projectConfigState);
  const [likeCount, setLikeCount] = useState(exam?.likeCount);
  const { logo } = useRecoilValue(projectConfigState);

  useEffect(() => {
    setLikeCount(exam?.likeCount);
  }, [exam]);

  const handleToggleFavorite = (examId: string) => {
    if (!user) {
      router.push(handleLoginRedirect(window.location.href));
      return;
    }
    toggleFavorite({
      examId,
      isFavorite: favorites[examId],
      setFavorites,
      setLikeCount,
    });
  };

  return (
    <TableRow key={index}>
      <td className="institution">
        {exam?.examUrl ? (
          <div className="img-wrapper">
            <img src={exam.examUrl} alt={exam.examName} />
            {exam?.institution ? exam.institution : '없음'}
          </div>
        ) : (
          <div className="empty-img-wrapper">
            <div className="empty-img">
              <img src={logo} alt={exam.examName} />
            </div>
            {exam?.institution ? exam.institution : '없음'}
          </div>
        )}
      </td>
      <td className="title">
        <Link href={`/exam/detail/${exam.examIdx}`}>{exam.examName}</Link>
      </td>
      <td>{Number(exam.examDetailCount).toLocaleString()}</td>
      <td>{Number(exam.problemCount).toLocaleString()}</td>
      <td>{Number(exam.viewCount).toLocaleString()}</td>
      <td className="favorite">
        <button onClick={() => handleToggleFavorite(exam.examIdx)}>
          {favorites[exam.examIdx] ? <FavoriteIconSolidSvg /> : <FavoriteIconSvg />}
        </button>
        <span>{likeCount}</span>
      </td>
    </TableRow>
  );
};

export default CbtListTableRow;

const TableRow = styled.tr`
  height: 100px;
  box-sizing: border-box;

  td {
    height: 100px;
    text-align: center;
    border-bottom: 1px solid #ddd;
  }

  .institution {
    .img-wrapper {
      img {
        width: 60px;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
    .empty-img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .empty-img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #f4f4f4;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        > img {
          width: 80%;
          height: auto;
        }
      }
    }
  }

  .favorite {
    span {
      display: block;
      margin-top: 5px;
      line-height: initial;
      font-size: 16px;
      color: #999;
    }
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    > a {
      display: block;
      width: 100%;
      height: 100px;
      line-height: 100px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
`;
