import React from 'react';
import { NextPage } from 'next';
import styled from 'styled-components';

type Tprops = {
  category: any;
};
type CategoryColor = {
  background: string;
  color: string;
};
const TagCbt: NextPage<Tprops> = ({ category }) => {
  if (Object.keys(categoryColors).includes(category))
    return <CategoryTag category={category}>{category}</CategoryTag>;
  else return <></>;
};

export default TagCbt;

const categoryColors: Record<string, CategoryColor> = {
  대기업: { background: '#DFF3FF', color: '#0367A5' },
  PSAT: { background: '#F1E6FA', color: '#7C3AAF' },
  공기업: { background: '#FFF6C7', color: '#813308' },
  한국사: { background: '#FCE4E8', color: '#B13A3A' },
  자격증: { background: '#EAFFE0', color: '#308C04' },
  default: { background: '#666', color: '#fff' },
};

const CategoryTag = styled.span<{ category: string }>`
  width: 53px;
  height: 27px;
  line-height: 27px;
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: ${({ category }) => (categoryColors[category] || categoryColors['default']).color};
  border-radius: 5px;
  margin: 5px auto;
  background-color: ${({ category }) =>
    (categoryColors[category] || categoryColors['default']).background};
`;
