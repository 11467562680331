import React from 'react';
import TitleCbt from 'app.feature/academy/component/title/TitleCbt';
import Category from 'app.feature/academy/cbt.category/component/pc/Category';
import CategorySearch from 'app.feature/academy/cbt.category/component/common/CategorySearch';
import styled from 'styled-components';

const CategoryPc = ({ examType, examList }: any) => {
  return (
    <StyledWrapper>
      <div className="header-wrapper">
        <TitleCbt title="시험 CBT" level={1} />
        <CategorySearch />
      </div>
      <Category examType={examType} examList={examList} />
    </StyledWrapper>
  );
};

export default CategoryPc;

const StyledWrapper = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;
