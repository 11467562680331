import React from 'react';
import styled from 'styled-components';
import ArrowSelectBox from 'src/app.feature/academy/component/icon/arrow-selecbox.png';
import useResponsive from '../../../../../utils/OneQuestion/hooks/useResponsive';
import CategorySearch from 'app.feature/academy/cbt.category/component/common/CategorySearch';
import { theme } from 'app.styled/theme';
import CbtListSortSelect from 'app.feature/academy/cbt.list/component/CbtListSortSelect';

type SortHeaderProps = {
  sortKey: string;
  handleSortChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  itemCount: number;
};

const CbtListSortHeader: React.FC<SortHeaderProps> = ({ sortKey, handleSortChange, itemCount }) => {
  const { isMobile } = useResponsive(768);

  return (
    <>
      {isMobile ? (
        <Header>
          <p>검색 결과 {itemCount}건</p>
          <CategorySearch />
          <CbtListSortSelect handleSortChange={handleSortChange} sortKey={sortKey} />
        </Header>
      ) : (
        <Header>
          <p>전체 {itemCount}개</p>
          <CbtListSortSelect handleSortChange={handleSortChange} sortKey={sortKey} />
        </Header>
      )}
    </>
  );
};

export default CbtListSortHeader;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;

  @media ${theme.device.mobile} {
    background-color: #fafafa;
    padding: 6px 20px;
    align-items: baseline;
    margin: 0;
  }

  p {
    font-size: 16px;
    color: #333;
    margin: 0;
    @media screen and (max-width: 768px) {
      font-size: 12px;
      color: #999;
      white-space: nowrap;
    }
  }
`;
