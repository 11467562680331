import React, { Suspense } from 'react';
import ScreenCbtBest from 'src/app.feature/academy/cbt.best/screen/ScreenCbtBest';
import ScreenCbtCategory from 'src/app.feature/academy/cbt.category/screen/ScreenCbtCategory';
import ScreenCbtList from 'src/app.feature/academy/cbt.list/screen/ScreenCbtList';

const ScreenCbtHome = ({ isShowBestList = true }: { isShowBestList?: boolean }) => {
  return (
    <Suspense>
      <ScreenCbtCategory />
      <ScreenCbtBest isShowBestList={isShowBestList} />
      <ScreenCbtList />
    </Suspense>
  );
};

export default ScreenCbtHome;
