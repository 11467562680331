import React from 'react';
import styled from 'styled-components';
import { theme } from 'app.styled/theme';
import { useQueries, useQuery } from '@tanstack/react-query';
import CategoryMobile from 'app.feature/academy/cbt.category/component/mobile/CategoryMobile';
import CategoryPc from 'app.feature/academy/cbt.category/component/pc/CategoryPc';
import { TExamType } from 'app.type/examTypes';
import ExamApi from 'lib/api/exam';

const useExamsQueries = ({ queries, enabled = true }: { queries: any; enabled?: boolean }) => {
  const result = useQueries({
    queries: queries.map((query: any) => ({
      queryKey: ['/apiV2/exams', query],
      queryFn: () => ExamApi.getExamList(query),
      enabled,
      staleTime: 0,
    })),
  });
  return result;
};

const ScreenCbtCategory = () => {
  const { data: examTypes = [] } = useQuery({
    queryKey: ['/apiV2/exam-types'],
    queryFn: ExamApi.getExamTypes,
  });

  const examList = useExamsQueries({
    queries: examTypes.map((type: TExamType) => ({
      examTypeIds: type.id,
      pageSize: 999,
    })),
  }).map(({ data }) => data);

  return (
    <StyledWrapper>
      <CategoryPc examType={examTypes} examList={examList} />
      <CategoryMobile examType={examTypes} />
    </StyledWrapper>
  );
};

export default ScreenCbtCategory;

const StyledWrapper = styled.div`
  max-width: 1148px;
  margin: 50px auto;

  @media ${theme.device.mobile} {
    margin: 0;
  }

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
`;
