import { axiosClient } from 'src/lib/api/axios';

type ToggleFavoriteOptions = {
  examId: string;
  isFavorite: boolean;
  setFavorites: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  setLikeCount?: any;
};

export const toggleFavorite = async ({
  examId,
  isFavorite,
  setFavorites,
  setLikeCount,
}: ToggleFavoriteOptions) => {
  setFavorites((prevFavorites) => ({
    ...prevFavorites,
    [examId]: !prevFavorites[examId],
  }));

  try {
    if (isFavorite) {
      await axiosClient({ method: 'POST', url: `/apiV2/exams/${examId}/unlike`, data: {} });
      setLikeCount((current: number) => current - 1);
    } else {
      await axiosClient({ method: 'POST', url: `/apiV2/exams/${examId}/like`, data: {} });
      setLikeCount((current: number) => current + 1);
    }
  } catch (error) {
    console.error('Error toggling favorite:', error);
    setFavorites((prevFavorites) => ({
      ...prevFavorites,
      [examId]: !prevFavorites[examId],
    }));
  }
};
