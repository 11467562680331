import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';

interface CategoryListProps {
  examList: any;
  showAll: boolean;
}

const CategoryList: React.FC<CategoryListProps> = ({ examList, showAll }: any) => {
  return (
    <StyledWrapper className={showAll ? 'expanded list-wrapper' : 'list-wrapper'}>
      {examList?.map((exam: any) => {
        return (
          <li key={exam.examIdx}>
            <Link
              href={{
                pathname: `/exam/detail/${exam.examIdx}`,
              }}
            >
              {exam.examName}
            </Link>
          </li>
        );
      })}
    </StyledWrapper>
  );
};

export default CategoryList;

const StyledWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  line-height: 57px;
  height: 57px;
  overflow: hidden;
  transition: height 1s ease;
  margin: 0;

  &.expanded {
    padding: 16px 0 16px 40px;
    height: auto;
    > li {
      line-height: 2.5;
    }
  }

  > li {
    position: relative;
    margin-right: 40px;
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 15px;
      background-color: #ddd;
      position: absolute;
      bottom: 50%;
      right: -21px;
      margin-bottom: -8px;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    > a {
      display: inline-block;
      width: 100%;
      min-width: max-content;
      font-size: 16px;
      color: #444;
    }
  }
`;
