export const convertExamQueryString = (query: any) => {
  const sortKey: string = query.sorts ? (query.sorts as string) : 'viewCount';
  const keyword: string = query.keyword ? (query.keyword as string) : '';

  const queryObj = {
    ...(query?.categoryId && { examTypeIds: [query?.categoryId] }),
    page: query.page ? Number(query.page) : 1,
    pageSize: 15,
    sorts: convertSortKey(sortKey),
    keyword: keyword,
  };

  return queryObj;
};

export const convertSortKey = (sort: string) => {
  if (sort === 'createdAt') return ['createdAt,desc', 'likeCount,desc', 'viewCount,desc'];
  if (sort === 'likeCount') return ['likeCount,desc', 'viewCount,desc', 'createdAt,desc'];
  return ['viewCount,desc', 'likeCount,desc', 'createdAt,desc'];
};
