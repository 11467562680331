import React from 'react';
import styled from 'styled-components';
import { theme } from 'app.styled/theme';
import { useRouter } from 'next/router';
import CbtListTable from 'app.feature/academy/cbt.list/component/CbtListTable';
import CbtListSortHeader from 'app.feature/academy/cbt.list/component/CbtListSortHeader';
import CbtListTablePagination from 'app.feature/academy/cbt.list/component/CbtListTablePagination';
import { TExams } from 'app.type/exams';
import { TPaginationData } from 'app.type/common';
import { TUserLikesExams } from 'app.type/userLikesExams';
import { useQuery } from '@tanstack/react-query';
import UserApi from 'lib/api/users';
import ExamApi from 'lib/api/exam';
import { useCurrentUser } from 'app.hooks/useCurrentUser';
import { convertExamQueryString } from '../../module/convertExamQueryString';

const ScreenCbtList = () => {
  const router = useRouter();
  const { user } = useCurrentUser();
  const pageSize = 15;
  const page = router.query.page ? Number(router.query.page) : 1;
  const sortKey = router.query.sorts ? (router.query.sorts as string) : 'viewCount';
  const type = router.query?.type ? String(router.query.type) : '';

  const { data: userLikeCbt = [] } = useQuery<TUserLikesExams>({
    queryKey: ['/apiV2/user/me/likes/exams'],
    queryFn: UserApi.getUserLikes,
    enabled: Boolean(user),
  });

  const { data: exams = { totalCount: 0, nodes: [] } } = useQuery<TPaginationData<TExams>>({
    queryKey: [
      type ? `/apiV2/exams/${type}` : '/apiV2/exams',
      convertExamQueryString(router.query),
    ],
    queryFn: () =>
      type
        ? ExamApi.getExamsByType(type, convertExamQueryString(router.query))
        : ExamApi.getExamList(convertExamQueryString(router.query)),
  });

  const handlePageChange = (page: number) => {
    router.push({
      pathname: router.pathname,
      query: { ...router.query, page },
    });
  };

  // 시험 타입별 시험 목록 조회
  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    router.push({
      pathname: router.pathname,
      query: { ...router.query, sorts: e.target.value, page: 1 },
    });
  };

  return (
    <TableWrapper>
      <CbtListSortHeader
        sortKey={sortKey}
        handleSortChange={handleSortChange}
        itemCount={exams?.totalCount}
      />
      <CbtListTable examList={exams.nodes} likeList={userLikeCbt} />
      <CbtListTablePagination
        total={exams.totalCount}
        pageSize={pageSize}
        current={page}
        handlePageChange={handlePageChange}
      />
    </TableWrapper>
  );
};

export default ScreenCbtList;

const TableWrapper = styled.div`
  max-width: 1148px;
  margin: 50px auto;
  @media ${theme.device.mobile} {
    margin: 0;
  }
`;
