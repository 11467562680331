import { useEffect, useState } from 'react';

/**
 *
 * @param mobileWidth - mobileWidth 보다 window가 크면 false , window가 작으면 true를 리턴합니다.
 * @returns
 */
export default function useResponsive(mobileWidth = 768) {
  const [isMobile, setIsMobile] = useState(false);
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );

  const handleResize = () => {
    if (mobileWidth < window.innerWidth) {
      setIsMobile(false);
    }
    if (mobileWidth > window.innerWidth) {
      setIsMobile(true);
    }
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { isMobile, windowWidth };
}
