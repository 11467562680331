import React from 'react';
import styled from 'styled-components';
import ArrowSelectBox from 'app.feature/academy/component/icon/arrow-selecbox.png';
import { theme } from 'app.styled/theme';

const CbtListSortSelect = ({ handleSortChange, sortKey }: any) => {
  return (
    <SelectBox id="sort-select" onChange={handleSortChange} value={sortKey}>
      <option value="viewCount">인기순</option>
      <option value="createdAt">최신순</option>
      <option value="likeCount">관심시험 추가 순</option>
    </SelectBox>
  );
};

export default CbtListSortSelect;

const SelectBox = styled.select`
  padding: 10px 35px 10px 10px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  appearance: none;
  background-image: url(${ArrowSelectBox.src});
  background-repeat: no-repeat;
  background-position: right 10px center;
  cursor: pointer;
  width: auto;
  min-width: 130px;

  @media ${theme.device.mobile} {
    font-size: 12px;
    color: #333;
    background-color: #fafafa;
  }

  &:focus {
    outline: none;
    border-color: #aaa;
  }
`;
