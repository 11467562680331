import React from 'react';
import { NextPage } from 'next';
import styled from 'styled-components';

type Tprops = {
  title: string;
  level: number;
};

const TitleCbt: NextPage<Tprops> = ({ title, level }) => {
  const HeadingTag = `h${level}` as keyof JSX.IntrinsicElements;

  return (
    <StyledWrapper>
      <HeadingTag>{title}</HeadingTag>
    </StyledWrapper>
  );
};

export default TitleCbt;

const StyledWrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    //margin-bottom: 20px;
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 14px;
  }
`;
