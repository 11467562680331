import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';

interface CategoryProps {
  examType?: any;
}

const CategoryMobile: React.FC<CategoryProps> = ({ examType }) => {
  const router = useRouter();
  const initialCategories = Array.isArray(router.query?.categoryId)
    ? router.query.categoryId.map((id: any) => Number(id))
    : router.query?.categoryId
    ? [Number(router.query.categoryId)]
    : [];

  const [selectedCategories, setSelectedCategories] = useState<number[]>(initialCategories);

  useEffect(() => {
    if (Array.isArray(router.query?.categoryId)) {
      setSelectedCategories([...router.query?.categoryId.map((id: any) => Number(id))]);
    } else {
      if (router.query?.categoryId) setSelectedCategories([Number(router.query?.categoryId)]);
    }
  }, [router]);

  const handleClickParentCategory = (categoryId: number) => {
    let updatedCategories: number[] = [];

    if (categoryId === 0) {
      updatedCategories = [];
    } else {
      if (selectedCategories.includes(0)) {
        updatedCategories = [categoryId];
      } else if (selectedCategories.includes(categoryId)) {
        updatedCategories = selectedCategories.filter((id) => id !== categoryId);
      } else {
        updatedCategories = [...selectedCategories, categoryId];
      }
    }

    setSelectedCategories(updatedCategories);

    if (updatedCategories.length === 0) {
      router.push(
        {
          pathname: router.pathname,
          query: { ...router.query, page: 1, categoryId: null },
        },
        undefined,
        { shallow: true }
      );
    } else {
      router.push(
        {
          pathname: router.pathname,
          query: { ...router.query, categoryId: updatedCategories, page: 1 },
        },
        undefined,
        { shallow: true }
      );
    }
  };

  return (
    <>
      <CategoryWrapper>
        <CategoryBox isSelected={selectedCategories.length === 0}>
          <button className="category-btn" onClick={() => handleClickParentCategory(0)}>
            전체
          </button>
        </CategoryBox>
        {(examType || [])?.map((category: any, index: number) => {
          const { id, name } = category;
          const isSelected = selectedCategories.includes(id);
          return (
            <CategoryBox key={id} isSelected={isSelected}>
              <button className="category-btn" onClick={() => handleClickParentCategory(id)}>
                {name}
              </button>
            </CategoryBox>
          );
        })}
      </CategoryWrapper>
    </>
  );
};

export default CategoryMobile;

const CategoryWrapper = styled.div`
  display: none;
  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 8px 20px;
    overflow-x: auto;
    white-space: nowrap;
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const CategoryBox = styled.div<{ isSelected: boolean }>`
  .category-btn {
    padding: 10px 15px;
    font-size: 13px;
    font-weight: 400;
    color: ${({ isSelected }) => (isSelected ? '#fff' : '#333')};
    background-color: ${({ isSelected }) => (isSelected ? '#01A0FF' : '#eeeeee')};
    border-radius: 20px;
    cursor: pointer;
  }
`;
